import axios from 'axios';
import {
  projectsByClient,
  propertiesByProject,
  milestonesByProject,
  documentsByProject,
  projectsByExecutive,
  getCalendars,
  clientsByProject,
  projectById,
  filesByProperty,
  uploadFile,
  activeClientsByProject,
  uploadFileMassive,
  listMassiveDocs,
  deleteFileMassive,
  downloadFile,
  deleteFile,
  listarImages,
  MenuDisponible,
  ListarMenusDisponibles,
  ModificarMenusDisponibles,
  profileListEndpoint,
  rolesListEndpoint,
  modifyProfileRoles,
  modifyUserRoles,
  userSubsidiaryProfileRoleListEndpoint,
  modifyUserProfileAssignment,
  unitTypesByProject,
  clientsByUnitType,
  userProfiles,
  listExistingDocs,
  updateDocClassification,
  listDocAgrupations
} from '../constants/endpoints';

export const getPropertiesByProject = ({ id_Proyecto: idProyecto }, formatUsername) => {
  return axios.post(propertiesByProject, { idProyecto, idCliente: formatUsername });
};

export const getOnlyPropertyByProject = (idProyecto, idCliente, successCallback, errorCallback) => {
  return axios
    .post(propertiesByProject, { idProyecto, idCliente })
    .then(successCallback)
    .catch(errorCallback);
};

export const getProjectById = (projectId, successCallback, errorCallback) => {
  return axios
    .post(`${projectById}`, { id_proyecto: projectId })
    .then(successCallback)
    .catch(errorCallback);
};

const handleProjectsProperties = async (
  { data: { proyectos: projectData = [] } = {}, ...restData },
  formatUsername,
) => {
  const formatedData = [];
  if (projectData.length > 0) {
    await Promise.all(
      projectData.map(async project => {
        const {
          data: { data: content },
        } = await getPropertiesByProject(project, formatUsername);
        formatedData.push({ propiedades: [...content], ...project });
      }),
    );
  } else {
    const { proyectos, propiedades } = restData;
    formatedData.push({ ...proyectos[0], propiedades });
  }
  return { formatedData };
};

export const getOnlyProjectsByUser = (username, successCallback, errorCallback) => {
  return axios
    .post(projectsByClient, {
      rut: username,
    })
    .then(successCallback)
    .catch(errorCallback);
};

export const getProjectsByUser = (username, successCallback, errorCallback) => {
  const formatUsername = username;
  return axios
    .post(projectsByClient, {
      rut: formatUsername,
    })
    .then(async data => {
      const { formatedData } = await handleProjectsProperties({ ...data }, formatUsername);
      successCallback(formatedData);
    })
    .catch(errorCallback);
};

export const getMilestonesByProject = (idProject, successCallback, errorCallback) => {
  return axios
    .post(milestonesByProject, {
      idProyecto: idProject,
    })
    .then(successCallback)
    .catch(errorCallback);
};

export const getImagesByProject = ({username, idProject}, successCallback, errorCallback) => {
  return axios
    .post(listarImages, {
      idProyecto: idProject,
      idCliente: username,
    })
    .then(successCallback)
    .catch(errorCallback);
}

export const getDocumentsByProject = (
  { username, idProyecto, idPropiedad },
  successCallback,
  errorCallback,
) => {
  const formatUsername = username;
  return axios
    .post(documentsByProject, {
      idProyecto,
      idCliente: formatUsername,
      idPropiedad,
    })
    .then(successCallback)
    .catch(errorCallback);
};

export const getProjectsByExecutive = (
  executiveRut,
  subsidiary = '',
  page = 1,
  limit = 10,
  successCallback,
  errorCallback,
) =>
  axios
    .post(
      `${projectsByExecutive}?page=${page}&limit=${limit}${
        subsidiary !== '' ? `&f=${subsidiary}` : ''
      }&order=asc`,
      {
        rut: executiveRut,
      },
    )
    .then(successCallback)
    .catch(errorCallback);

export const getFileList = (projectId, propertyId, clientId, successCallback, errorCallback) => {
  return axios
    .post(`${filesByProperty}`, {
      idProyecto: projectId,
      idPropiedad: propertyId,
      idCliente: clientId,
    })
    .then(successCallback)
    .catch(errorCallback);
}

export const postFile = (
  file,
  idProyecto,
  idCliente,
  idPropiedad,
  label,
  successCallback,
  errorCallback,
) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('idProyecto', idProyecto);
  formData.append('idCliente', idCliente);
  formData.append('idPropiedad', idPropiedad);
  formData.append('label', label);
  return axios
    .post(uploadFile, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(successCallback)
    .catch(errorCallback);
};

export const deleteClientFile = (
  idProyecto,
  idPropiedad,
  idCliente,
  label,
  name,
  successCallback,
  errorCallback,
) => {
  axios
    .post(deleteFile, { idProyecto, idPropiedad, idCliente, label, name })
    .then(successCallback)
    .catch(errorCallback);
};

export const getCalendar = (
  { idPropiedad, idProyecto, idCliente, filterDate },
  successCallback,
  errorCallback,
) =>
  axios
    .post(`${getCalendars}`, {
      id_propiedad: idPropiedad,
      id_proyecto: idProyecto,
      id_cliente: idCliente,
      fecha_filtro: filterDate,
    })
    .then(successCallback)
    .catch(errorCallback);

export const getClientsByProject = (
  idProyecto,
  page = 1,
  limit = 100,
  letterFilter = '*',
  textFilter = '*',
  successCallback,
  errorCallback,
) => {
  axios
    .post(
      `${clientsByProject}?page=${page}&filter=${textFilter}&limit=${limit}${
        letterFilter && letterFilter !== '' ? `&q=${letterFilter}` : ''
      }`,
      {
        id_proyecto: idProyecto,
      },
    )
    .then(successCallback)
    .catch(errorCallback);
}

export const getActiveClientsByProject = (
  idProyecto,
  filterByLetter,
  successCallback,
  errorCallback,
) => {
  axios
    .post(
      `${activeClientsByProject}${filterByLetter &&
        filterByLetter !== '' &&
        `?q=${filterByLetter}`}`,
      { idProyecto },
    )
    .then(successCallback)
    .catch(errorCallback);
};

export const getUnitTypesByProject = (projectId, successCallback, errorCallback) => {
  axios
    .get(`${unitTypesByProject}/${projectId}`)
    .then(successCallback)
    .catch(errorCallback);
};

export const getClientsByUnitType = (projectId, unitTypeId, successCallback, errorCallback) => {
  axios
    .get(`${clientsByUnitType}/${projectId}/${unitTypeId}`)
    .then(successCallback)
    .catch(errorCallback);
};

export const uploadWholeProject = (
  idProyecto,
  name,
  file,
  to,
  clients,
  successCallback,
  errorCallback,
) => {
  const formData = new FormData();
  formData.append('file', file[0]);
  formData.append('idProyecto', idProyecto);
  formData.append('label', name);
  formData.append('destinatario', to);
  formData.append('clientes', JSON.stringify(clients));
  return axios
    .post(`${uploadFileMassive}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(successCallback)
    .catch(errorCallback);
};

export const getMassiveListDocsByProject = (id_proyecto, successCallback, errorCallback) =>
  axios
    .get(`${listMassiveDocs}/${id_proyecto}`)
    .then(successCallback)
    .catch(errorCallback);

export const deleteDocMassiveByProject = (docId, successCallback, errorCallback) =>
  axios
    .delete(`${deleteFileMassive}${docId}`)
    .then(successCallback)
    .catch(errorCallback);

export const downloadDoc = (fileName, successCallback, errorCallback) =>
  axios
    .post(downloadFile, { fileName })
    .then(successCallback)
    .catch(errorCallback);

export const checkSubsidiarysMenus = (subsidiary, successCallback, errorCallback) =>
  axios
    .get(`${MenuDisponible}/${subsidiary}`)
    .then(successCallback)
    .catch(errorCallback);

export const checkAvailableMenus = (successCallback, errorCallback) =>
  axios
    .get(`${ListarMenusDisponibles}`)
    .then(successCallback)
    .catch(errorCallback);

export const modifyAvailableMenus = (subsidiary, updatedData, successCallback, errorCallback) =>
  axios
    .post(`${ModificarMenusDisponibles}/${subsidiary}`, updatedData)
    .then(successCallback)
    .catch(errorCallback);

export const listAllRoles = (successCallback, errorCallback) =>
  axios
    .get(`${rolesListEndpoint}`)
    .then(successCallback)
    .catch(errorCallback);

export const listAllProfiles = (successCallback, errorCallback) =>
  axios
    .get(`${profileListEndpoint}`)
    .then(successCallback)
    .catch(errorCallback);

export const listRolesByProfile = (profileId, successCallback, errorCallback) =>
  axios
    .get(`${rolesListEndpoint}/${profileId}`)
    .then(successCallback)
    .catch(errorCallback);

export const updateProfileRoles = (updatedProfile, successCallback, errorCallback) =>
  axios
    .post(`${modifyProfileRoles}`, updatedProfile)
    .then(successCallback)
    .catch(errorCallback);

export const updateUserRoles = (updatingBody, successCallback, errorCallback) =>
  axios
    .post(`${modifyUserRoles}`, updatingBody)
    .then(successCallback)
    .catch(errorCallback);

export const updateUserProfiles = (updatingBody, successCallback, errorCallback) =>
  axios
    .post(`${modifyUserProfileAssignment}`, updatingBody)
    .then(successCallback)
    .catch(errorCallback);

export const listUserSubsidiaryProfileRoles = (executiveId, successCallback, errorCallback) =>
  axios
    .get(`${userSubsidiaryProfileRoleListEndpoint}/${executiveId}`)
    .then(successCallback)
    .catch(errorCallback);

export const listUserProfiles = (executiveId, successCallback, errorCallback) =>
  axios
    .get(`${userProfiles}/${executiveId}`)
    .then(successCallback)
    .catch(errorCallback);
export const listDocumentTypes = (successCallback, errorCallback) =>
  axios
    .get(`${listExistingDocs}`)
    .then(successCallback)
    .catch(errorCallback);

export const updateDocumentClassification = (updatingBody, successCallback, errorCallback) =>
  axios
    .post(`${updateDocClassification}`, updatingBody)
    .then(successCallback)
    .catch(errorCallback);

export const getDocAgrupations = (successCallback, errorCallback) => {
  axios
    .get(`${listDocAgrupations}`)
    .then(successCallback)
    .catch(errorCallback);
};