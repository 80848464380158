import { useContext } from 'react';
import { GlobalContext } from '../providers/GlobalStateProvider';

export function useHasPermission(subsidiaryName, incumbentTypes = null) {
  const {
    state: { permissions },
  } = useContext(GlobalContext);
  let rolesObject = {};
  if (subsidiaryName == null || !permissions || !permissions.subsidiaries) {
    return rolesObject;
  }
  const subsidiary = permissions.subsidiaries.find(s => s.name === subsidiaryName);
  if (subsidiary) {
    if (incumbentTypes === null) {
      for (let incumbent of subsidiary.incumbents) {
        const incumbentRoles = incumbent.roles.reduce((acc, role) => {
          acc[role.name] = true;
          return acc;
        }, {});
        rolesObject = { ...rolesObject, ...incumbentRoles };
      }
    } else {
      for (let type of incumbentTypes) {
        const incumbent = subsidiary.incumbents.find(inc => inc.type === type);
        if (incumbent) {
          const incumbentRoles = incumbent.roles.reduce((acc, role) => {
            acc[role.name] = true;
            return acc;
          }, {});
          rolesObject = { ...rolesObject, ...incumbentRoles };
        }
      }
    }
  }

  return rolesObject;
}

export function getUniquePropertyStates(properties) {
  return properties.reduce((acc, property) => {
    if (!acc.includes(property.nombreestado)) {
      acc.push(property.nombreestado);
    }
    return acc;
  }, []);
}
