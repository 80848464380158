import React, { useState, useEffect } from 'react';
import { Typography, Container } from '@material-ui/core';
import * as XLSX from 'xlsx';
import DocClassificationCard from '../../components/DocClassificationCard/DocClassificationCard';
import { listDocumentTypes, getDocAgrupations } from '../../services/projectsService';
import Button from '../../components/ButtonTypes';
import './DocumentsClassification.scss';


const DocumentsClassification = () => {

  const [docTypes, setDocTypes] = useState([]);
  const [docAgrupations, setDocAgrupations] = useState([]);

  useEffect(() => {
    listDocumentTypes(response => setDocTypes(response.data), error => console.error(error));
    getDocAgrupations(response => setDocAgrupations(response.data), error => console.error(error));
  }, []);

const clasificacionesObj = docAgrupations.reduce((acc, curr) => {
  acc[curr.nombreAgrupacion] = curr.nombreparaMostrar;
  return acc;
}, {});

  // Generar clasificacionesMap dinámicamente
  const createClasificacionesMap = (docTypes) => {
    const uniqueKeys = new Set();

    docTypes.forEach(doc => {
        Object.keys(doc).forEach(key => {
            if (key !== "id" && key !== "tipodocumento" && key !== "fecha" && key !== "vigente" && key !== "clase") {
                uniqueKeys.add(key);
            }
        });
    });

    // Asignar un ID único a cada clasificación
    const clasificacionesMap = {};
    Array.from(uniqueKeys).forEach((key, index) => {
        clasificacionesMap[key] = index + 1; // IDs empiezan desde 1
    });

    return clasificacionesMap;
  };

  // Crear el mapa
  const clasificacionesMap = createClasificacionesMap(docTypes);

  // Transformar docTypes con el mapa generado
  const transformedDocTypes = docTypes.map(doc => {
    const clasificaciones = Object.entries(doc)
        .filter(([key, value]) => value === 1 && key in clasificacionesMap)
        .map(([key]) => ({
            clasificacion: key,
            id: clasificacionesMap[key]
        }));

    return {
        idDoc: doc.id,
        tipodocumento: doc.tipodocumento,
        clasificaciones: clasificaciones
    };
  }).sort((a, b) => a.idDoc - b.idDoc);

  const [isExpanded, setIsExpanded] = useState(false);

  // Función para exportar a Excel
  const handleExportExcel = () => {
    // Crear la matriz para Excel
    const data = [
      ['ID', 'Tipo de Documento', ...Object.keys(clasificacionesObj)], // Encabezados
      ...docTypes
        .sort((a, b) => a.id - b.id) // Ordenar por ID de forma ascendente
        .map((doc) => {
          const row = [
            doc.id,
            doc.tipodocumento,
            ...Object.keys(clasificacionesObj).map((key) => (doc[key] === 1 ? '✔' : '')),
          ];
          return row;
        }),
    ];

    // Crear el libro y la hoja
    const worksheet = XLSX.utils.aoa_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Clasificaciones');

    // Generar el archivo y descargarlo
    XLSX.writeFile(workbook, 'ClasificacionesDocumentos.xlsx');
  };



  return (
    <div className='DocumentsClassification'>
      <Typography variant="h2" className="DocumentsClassification__title">
        CLASIFICACIÓN DE DOCUMENTOS
      </Typography>
      <Container maxWidth="lg" className="DocumentsClassification_container">
        <Button onClick={handleExportExcel}>
          Descargar Matriz
        </Button>
        {transformedDocTypes.map(docType => (
          <DocClassificationCard
            id={docType.idDoc}
            name={docType.tipodocumento}
            selectedDocTypeOptions={docType.clasificaciones}
            isExpanded={isExpanded}
            allOptions={clasificacionesMap}
            classificationsNames={clasificacionesObj}
            consultaAgrupacionDocumentos={docAgrupations}
          />
        ))}
      </Container>
    </div>
  );
};
export default DocumentsClassification;