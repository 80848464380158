import React, { createContext, useReducer, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { reducer, inicialState } from '../reducers/globalStateReducer';
import { USER_LOGIN, USER_LOGOUT, TOOGLE_STATE_LOAD } from '../actions/actionTypes';

import { HandlePersistence, updateStorage, clearStorage } from '../customHooks/handlePersistence';

const GlobalContext = createContext('global');

const GlobalStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, inicialState);
  const updateToken = useCallback(
    (newToken, username, fullName, rut, subsidiary, mail, idAdditional, isOwner, isAdditional, isAdmin, permissions) => {
      dispatch({
        type: USER_LOGIN,
        token: newToken,
        username,
        fullName,
        rut,
        subsidiary,
        mail,
        idAdditional,
        isOwner,
        isAdditional,
        isAdmin,
        permissions,
      });
      updateStorage({
        token: newToken,
        username,
        fullName,
        rut,
        subsidiary,
        idAdditional,
        isOwner,
        isAdditional,
        isAdmin,
        permissions,
      });
    },
    [],
  );
  const rehydrateState = useCallback(
    ({ token, username, fullName, rut, subsidiary, idAdditional, isOwner, isAdditional, isAdmin, permissions }) => {
      dispatch({
        type: USER_LOGIN,
        token,
        username,
        fullName,
        rut,
        subsidiary,
        idAdditional,
        isOwner,
        isAdditional,
        isAdmin,
        permissions,
      });
    },
    [],
  );
  const logOut = useCallback(callback => {
    dispatch({ type: USER_LOGOUT, callback });
    clearStorage();
  }, []);
  const toogleLoad = useCallback(() => {
    dispatch({ type: TOOGLE_STATE_LOAD });
  });
  const [histNav, setHistNav] = useState([
    {
      nav: 'almagro',
      action: [
        () => {
          console.log('Inicio');
        },
      ],
    },
  ]);
  HandlePersistence(state, rehydrateState, toogleLoad);
  return (
    <GlobalContext.Provider value={{ state, dispatch, updateToken, logOut, histNav, setHistNav }}>
      {children}
    </GlobalContext.Provider>
  );
};

GlobalStateProvider.propTypes = {
  reducer: PropTypes.func,
  initialState: PropTypes.object,
  children: PropTypes.node,
};

export { GlobalStateProvider, GlobalContext };
