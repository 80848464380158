import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Typography,
} from '@material-ui/core';
import Button from '../ButtonTypes';
import { listRolesByProfile } from '../../services/projectsService';

function toTitleCase(str) {
  return str
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

const filialesMap = { almagro: 1, pilares: 5, intranet: 10, socovesa: 2 };

const RolesDialog = ({
  executive,
  subsidiary,
  executivePermissions,
  onPermissionsUpdate,
  profile,
  allPermissions,
  open,
  handleCancel,
  handleSave,
}) => {
  const [selectedProfile, setSelectedProfile] = useState({});
  const [selectedProfileName, setSelectedProfileName] = useState('');
  const [selectedRoles, setSelectedRoles] = useState({});
  const [selectedProfileRoles, setSelectedProfileRoles] = useState([]);
  const [changedCheckboxes, setChangedCheckboxes] = useState(false);

  useEffect(() => {
    const initialRoles = {};
    allPermissions.forEach(permission => {
      initialRoles[permission.idRol] = executivePermissions.includes(permission.nombreRol);
    });
    setSelectedRoles(initialRoles);
  }, [profile, allPermissions, executivePermissions]);

  useEffect(() => {
    if (profile && profile.idPerfil) {
      setSelectedProfile(profile);
      setSelectedProfileName(profile.nombrePerfil)
      listRolesByProfile(
        profile.idPerfil,
        response => {
          setSelectedProfileRoles(response.data.data);
        },
        error => console.error(error),
      );
    }
  }, [profile]);

  const handleCheckboxChange = (roleId, isChecked) => {
    setSelectedRoles(prevRoles => ({
      ...prevRoles,
      [roleId]: isChecked,
    }));
    setChangedCheckboxes(true);
  };

  const updatedPermissions = allPermissions.map(permission => ({
    ...permission,
    nombreRol: toTitleCase(permission.nombreRol),
  }));

  const subtractedPermissions = updatedPermissions.filter(
    permission => !selectedProfileRoles.some(sp => sp.idRol === permission.idRol),
  );

  const onSave = () => {
    const rolesString = Object.keys(selectedRoles)
      .filter(key => selectedRoles[key])
      .join(',');
    const dataToSave = {
      usuario: parseInt(executive.rut),
      perfil: selectedProfile.idPerfil,
      filial: filialesMap[subsidiary],
      listaroles: rolesString,
    };
    handleSave(dataToSave);
  };

  const renderCheckboxes = permissions =>
    Array.isArray(permissions) &&
    permissions.map(permission => (
      <div key={permission.idRol}>
        <FormControlLabel
          control={
            <input
              type="checkbox"
              className="permissionCheckbox"
              checked={selectedRoles[permission.idRol] || false}
              onChange={e => handleCheckboxChange(permission.idRol, e.target.checked)}
            />
          }
          label={permission.nombreRol}
        />
      </div>
    ));

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      className="PermissionDialog"
      fullWidth
      maxWidth="md"
      PaperProps={{ style: { maxWidth: 'lg' } }}
    >
      <DialogContent>
        <Typography variant="h3" className="PermissionDialog__title">
          PERSONALIZAR PERMISOS
        </Typography>
        <Typography variant="h4" className="PermissionDialog__subtitle">
          COMO PERFIL {selectedProfileName.toUpperCase()} EN FILIAL {subsidiary.toUpperCase()}
        </Typography>
        <div div className="ProfilePermissions">
          <h4 className="ProfilePermissions__title">
            Permisos asignados por el perfil {selectedProfileName}{' '}
          </h4>
          <div className="ProfilePermissions__checkbox">
            {renderCheckboxes(selectedProfileRoles)}
          </div>
        </div>
        <div className="OtherPermissions">
          <h4 className="OtherPermissions__title">Otros permisos para asignar</h4>
          <div className="OtherPermissions__checkbox">
            {renderCheckboxes(subtractedPermissions)}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancelar
        </Button>
        <Button onClick={onSave} color="primary" disabled={!changedCheckboxes} autoFocus>
          Guardar cambios
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RolesDialog;
