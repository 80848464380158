import React, { useState, Fragment, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import MilestonCalendarForm from './MilestoneCalendarForm';
import RightArrowIcon from '../Icons/RightArrowIcon';
import Button from '../ButtonTypes';
import { formatDate } from '../../utils/utils';
import PlusIconButton from '../Icons/PlusIconButton';
import ClearIcon from '../Icons/ClearIcon';
import EditButtonIcon from '../Icons/EditButtonIcon';
import CloseButtonIcon from '../Icons/CloseButtonIcon';
import { useHasPermission } from '../../customHooks/usePermissions';

import {
  getMilestonsCalendar,
  createNewMilestonsCalendar,
  deleteMilestonsCalendar,
  editMilestonsCalendar,
} from '../../services/clientService';

import './MilestonCalendar.scss';

const DeleteButton = ({ onClick, className }) => (
  <Button onClick={onClick} variant="text" className={className}>
    <span className="DeleteButton">
      <ClearIcon color="currentColor" className="DeleteButton__container" />
      <span className="DeleteButton__label">eliminar</span>
    </span>
  </Button>
);

const MilestonCalendar = ({ idProject, clientId, propertyId, propertyNumber }) => {
  const [MilestoneCalendarList, setMilestoneCalendarList] = useState([]);
  const [showAddNewMilestonForm, setShowAddNewMilestonForm] = useState(false);
  const [editObj, setEditObj] = useState({});

  const showNewMilestonForm = () => {
    setShowAddNewMilestonForm(!showAddNewMilestonForm);
  };

  const getDataList = () => {
    if (!idProject && !propertyId && !clientId) return

    getMilestonsCalendar(
      idProject,
      propertyId,
      clientId,
      data => {
        const result = data.data.data.map(item => {
          item.numeroDepto = propertyNumber
          return item;
        });
        setMilestoneCalendarList([...result]);
      },
      error => {
        console.error(error);
      },
    );
  };

  useEffect(() => {
    if (clientId && propertyId && idProject) {
      getDataList();
    }
  }, [clientId, propertyId, idProject]);



  const addNewMilestoneCalendar = newMilestoneCalendar => {
    // const propSelected = propertiesSelected.filter(prop => prop);
    const data = {
      id_proyecto: idProject,
      id_propiedad: propertyId,
      id_cliente: clientId,
      fecha_desde: newMilestoneCalendar.fecha_desde,
      fecha_hasta: newMilestoneCalendar.fecha_hasta,
      descripcion: newMilestoneCalendar.introductorio,
      titulo: newMilestoneCalendar.title,
    };

    createNewMilestonsCalendar(
      data,
      () => {
        getDataList();
        showNewMilestonForm();
      },
      error => {
        console.error(error);
      },
    );
  };

  const openEditMilestoneCalendar = milestoneObj => {
    setEditObj(milestoneObj);
    showNewMilestonForm();
  };

  const editMilestoneCalendarItem = (idMongoMilestone, data) => {
    editMilestonsCalendar(
      idMongoMilestone,
      data,
      () => {
        getDataList();
      },
      error => {
        console.error('Error deleting milestoneCalendar item', error);
      },
    );
  };

  const deleteMilestoneCalendarItem = idMongoMilestone => {
    deleteMilestonsCalendar(
      idMongoMilestone,
      () => {
        getDataList();
      },
      error => {
        console.error('Error deleting milestoneCalendar item', error);
      },
    );
  };

  const handleSubmit = values => {
    addNewMilestoneCalendar(values);
  };

  const permissions = useHasPermission(sessionStorage.getItem('filial'), null);

  return (
    <Fragment>
      {!showAddNewMilestonForm ? (
        <div>
          <div
            className="MilestoneCalendar"
            style={
              MilestoneCalendarList && MilestoneCalendarList.length > 2
                ? { height: '343px', overflowY: 'scroll' }
                : {}
            }
          >
            <div className="MilestoneCalendar__items_head">
              <Grid container spacing={12}>
                <Grid item xs={2}>
                  <span>Propiedad</span>
                </Grid>
                <Grid item xs={4}>
                  <span>Fecha</span>
                </Grid>
                <Grid item xs={3}>
                  <span>Hito</span>
                </Grid>
                <Grid item xs={3}>
                  <span>Opciones</span>
                </Grid>
              </Grid>
            </div>

            {MilestoneCalendarList && MilestoneCalendarList.length > 0 ? (
              MilestoneCalendarList.map(value => {
                return (
                  <div className="MilestoneCalendar__items" key={value._id}>
                    <ExpansionPanel
                      defaultExpanded={false}
                      className="MilestoneCalendar__items_head__item"
                    >
                      <ExpansionPanelSummary>
                        <Grid container spacing={12}>
                          <Grid item xs={2} className="MilestoneCalendar__items__cols">
                            {value.numeroDepto}
                          </Grid>
                          <Grid item xs={4} className="MilestoneCalendar__items__cols">
                            {formatDate(new Date(value.fecha_desde), 'dd LLLL')}
                            {value.fecha_hasta &&
                              ` - ${formatDate(new Date(value.fecha_hasta), 'dd LLLL')}`}
                          </Grid>

                          <Grid item xs={3}>
                            <div className="MilestoneCalendar__items__doc_text">{value.titulo}</div>
                          </Grid>

                          <Grid item xs={1}>
                            <Button variant="text" onClick={() => openEditMilestoneCalendar(value)}>
                              <span>
                                <EditButtonIcon />
                                editar
                              </span>
                            </Button>
                          </Grid>

                          <Grid item xs={1}>
                            <Button
                              variant="text"
                              onClick={() => deleteMilestoneCalendarItem(value._id)}
                            >
                              <span>
                                <CloseButtonIcon />
                                eliminar
                              </span>
                            </Button>
                          </Grid>

                          <Grid item xs={1}>
                            <Button variant="text" className="MilestoneCalendar__arrows">
                              <RightArrowIcon color="#0093ca" />
                            </Button>
                          </Grid>
                        </Grid>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>{value.descripcion}</ExpansionPanelDetails>
                    </ExpansionPanel>
                  </div>
                );
              })
            ) : (
              <div>No existen hitos de calendario </div>
            )}
          </div>
          {/* {!!permissions['Crear Nuevos Hitos'] && ( */}
            <div className="MilestoneCalendar__footer">
              <Button variant="text" onClick={() => showNewMilestonForm()}>
                <span className="MilestoneCalendar__footer__addMoreLabel">
                  CREAR NUEVOS HITOS
                  <PlusIconButton
                    color="#0093ca"
                    className="NoteList__buttonContainer__button__component"
                  />
                </span>
              </Button>
            </div>
          {/* )} */}
        </div>
      ) : (
        <MilestonCalendarForm
          handleShowNewMilestonForm={showNewMilestonForm}
          addNewMilestoneCalendar={addNewMilestoneCalendar}
          editObj={editObj}
          editMilestoneCalendarItem={editMilestoneCalendarItem}
          onSubmit={handleSubmit}
        />
      )}
    </Fragment>
  );
};

MilestonCalendar.propTypes = {};

export default MilestonCalendar;
