import axios from 'axios';

import {
  ActasbyProducto,
  ActualizarRequerimiento,
  AgregarImagenRequerimiento,
  AgregarRequerimiento,
  AgregarSolicitud,
  clientByRut,
  ComponentesPorElemento,
  ConfirmarSolicitud,
  ElementosPorRecinto,
  FallasPorComponente,
  getImagenesPorRequerimiento,
  ImagenActa,
  ModificarImagenRequerimiento,
  NotificarRequerimiento,
  PropiedadesporClientePV,
  PropiedadesporClientePVAdditional,
  PropiedadesporAdministradorPV,
  RecintosPorUnidad,
  RequerimientosPorSolicitud,
  SolicitudesporUnidadPV,
  CrearAdicional,
  ModificarAdicional,
  AdicionalesPorRut,
  RelacionesPorRut,
  statusByBusinessId,
  UpdateStatusByBusinessId
} from '../constants/endpoints';
import { PropiedadesPostVentaAdittional } from './additionalMock'

export const getPropiedadesPostVenta = (userId, successCallback, errorCallback) => {
  return axios
    .get(`${PropiedadesporClientePV}/${userId}`)
    .then(successCallback)
    .catch(errorCallback);
};

export const getPropiedadesPostVentaAdittional = (additionalId, successCallback, errorCallback) => {
  return axios
    .get(`${PropiedadesporClientePVAdditional}/${additionalId}`)
    .then(successCallback)
    .catch(errorCallback);
};

export const getPropiedadesPostVentaAdmin = (rut, successCallback, errorCallback) => {
  return axios
    .get(`${PropiedadesporAdministradorPV}/${rut}`)
    .then(successCallback)
    .catch(errorCallback);
};

export const getImagenesRequerimiento = (idRequerimiento, successCallback, errorCallback) => {
  return axios
    .get(`${getImagenesPorRequerimiento}/${idRequerimiento}`)
    .then(successCallback)
    .catch(errorCallback);
};

export const getSolicitudesPostVenta = (unidadId, additionalRut, successCallback, errorCallback) => {
  return axios
    .get(`${SolicitudesporUnidadPV}/${unidadId}${!!additionalRut ? '?rut_solicitante=' + additionalRut : ''}`)
    .then(successCallback)
    .catch(errorCallback);
};

export const getActasbyProducto = (productoId, successCallback, errorCallback) => {
  return axios
    .get(`${ActasbyProducto}/${productoId}`)
    .then(successCallback)
    .catch(errorCallback);
};

export const getImagenActa = (actaId, productoId, successCallback, errorCallback) => {
  return axios
    .get(`${ImagenActa}/${productoId}/${actaId}`)
    .then(successCallback)
    .catch(errorCallback);
};

export const getClientData = (id, successCallback, errorCallback) => {
  return axios
    .get(`${clientByRut}/${id}`)
    .then(successCallback)
    .catch(errorCallback);
};

export const deleteClient = (id, successCallback, errorCallback) => {
  return axios
    .delete(clientByRut, {
      data: {
        id_cliente: id,
      },
    })
    .then(successCallback)
    .catch(errorCallback);
};

export const getRequerimientosPostVenta = (solicitudId, successCallback, errorCallback) => {
  return axios
    .get(`${RequerimientosPorSolicitud}/${solicitudId}`)
    .then(successCallback)
    .catch(errorCallback);
};

export const confirmSolicitud = (solicitudId, successCallback, errorCallback) => {
  return axios
    .post(`${ConfirmarSolicitud}`, { solicitudId })
    .then(successCallback)
    .catch(errorCallback);
};

export const getRecintosPorUnidad = (unidadId, successCallback, errorCallback) => {
  return axios
    .get(`${RecintosPorUnidad}/${unidadId}`)
    .then(successCallback)
    .catch(errorCallback);
};

export const getElementosPorRecinto = (recintoid, successCallback, errorCallback) => {
  return axios
    .get(`${ElementosPorRecinto}/${recintoid}`)
    .then(successCallback)
    .catch(errorCallback);
};

export const getComponentesPorElemento = (elementoid, successCallback, errorCallback) => {
  return axios
    .get(`${ComponentesPorElemento}/${elementoid}`)
    .then(successCallback)
    .catch(errorCallback);
};

export const getFallasPorComponente = (componenteid, successCallback, errorCallback) => {
  return axios
    .get(`${FallasPorComponente}/${componenteid}`)
    .then(successCallback)
    .catch(errorCallback);
};

export const agregarSolicitud = (
  rut_solicitante,
  adicional,
  id_delegado,
  id_unidad,
  tipo_solicitud,
  id_additional,
  successCallback,
  errorCallback,
) => {
  return axios
    .post(`${AgregarSolicitud}`, { id_delegado, id_unidad, rut_solicitante, adicional, tipo_solicitud, id_additional })
    .then(successCallback)
    .catch(errorCallback);
};

export const agregarRequerimiento = (
  id_solicitud,
  id_unidad,
  id_recinto,
  id_elemento,
  id_componente,
  id_falla,
  requerimiento,
  successCallback,
  errorCallback,
) => {
  return axios
    .post(`${AgregarRequerimiento}`, {
      id_solicitud,
      id_unidad,
      id_recinto,
      id_elemento,
      id_componente,
      id_falla,
      requerimiento,
    })
    .then(successCallback)
    .catch(errorCallback);
};

export const modificarRequerimiento = (
  id_requerimiento,
  recintoEspecifico,
  elemento,
  componente,
  falla,
  descripcion,
  successCallback,
  errorCallback,
) => {
  const requerimiento = { recintoEspecifico, elemento, componente, falla, descripcion };
  return axios
    .post(`${ActualizarRequerimiento}`, { id_requerimiento, requerimiento })
    .then(successCallback)
    .catch(errorCallback);
};

export const agregarImagenARequerimiento = (
  id_requerimiento,
  imagen,
  successCallback,
  errorCallback,
) => {
  let formData = new FormData();
  formData.append('id_requerimiento', id_requerimiento);
  formData.append('file', imagen);
  return axios
    .post(`${AgregarImagenRequerimiento}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(successCallback)
    .catch(errorCallback);
};

export const notificarNuevoRequerimiento = (notificacion, successCallback, errorCallback) => {
  return axios
    .post(`${NotificarRequerimiento}`, notificacion)
    .then(successCallback)
    .catch(errorCallback);
};

export const modificarImagenARequerimiento = (
  id_requerimiento,
  imagen,
  successCallback,
  errorCallback,
) => {
  let formData = new FormData();
  formData.append('id_requerimiento', id_requerimiento);
  formData.append('file', imagen);
  return axios
    .post(`${ModificarImagenRequerimiento}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(successCallback)
    .catch(errorCallback);
};

export const agregarAdicional = (
  nombre,
  rut,
  email,
  telefono,
  relacion,
  estado,
  unidadesAdicional,
  propietario,
  filial,
  nombreCliente,
  successCallback,
  errorCallback,
) => {
  // capitalizar nombre y nombreCliente
  nombre = nombre.toLowerCase().replace(/\b[a-z]/g, (letter) => letter.toUpperCase());
  nombreCliente = nombreCliente.toLowerCase().replace(/\b[a-z]/g, (letter) => letter.toUpperCase());
  return axios
    .put(`${CrearAdicional}`, { nombre, rut, email, telefono, relacion, estado, unidadesAdicional, propietario, filial, nombreCliente })
    .then(successCallback)
    .catch(errorCallback);
};

export const editarAdicional = (
  id,
  nombre,
  rut,
  email,
  telefono,
  relacion,
  estado,
  unidadesAdicional,
  propietario,
  nombrePropietario,
  filial,
  successCallback,
  errorCallback,
) => {
  return axios
    .post(`${ModificarAdicional}`, { id, nombre, rut, email, telefono, relacion, estado, unidadesAdicional, propietario, nombrePropietario, filial })
    .then(successCallback)
    .catch(errorCallback);
};

export const getAdicionales = (rut, successCallback, errorCallback) => {
  return axios
    .get(`${AdicionalesPorRut}/${rut}`)
    .then(successCallback)
    .catch(errorCallback);
};

export const getRelaciones = (rut, successCallback, errorCallback) => {
  return axios
    .get(`${RelacionesPorRut}/${rut}`)
    .then(successCallback)
    .catch(errorCallback);
};

export const getStatusByBusinessId = (idNegocio, idUsuario, successCallback, errorCallback) => {
  return axios
    .get(`${statusByBusinessId}/${idNegocio}/${idUsuario}`)
    .then(successCallback)
    .catch(errorCallback);
};

export const updateStatusByBusinessId = (idNegocio, idUsuario, estado, successCallback, errorCallback) => {
  return axios
    .post(`${UpdateStatusByBusinessId}`, { id_negocio: idNegocio, id_usuario: idUsuario, estado })
    .then(successCallback)
    .catch(errorCallback);
};

