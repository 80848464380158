import React, { useState, useMemo, useContext } from 'react';
import { Typography } from '@material-ui/core';

import { getExecutiveList } from '../../services/executive';
import { EnterpriseContext } from '../../providers/EnterpriseProvider';

import ExecutiveListItem from './ExecutiveListItem';

import './ExecutiveList.scss';

const useExecutiveList = () => {
  const [executiveList, setExecutiveList] = useState([]);
  const [activeExecutive, setActiveExecutive] = useState('');
  useMemo(() => {
    getExecutiveList(
      ({ data: { data } }) => {
        if (data.length > 0) {
          setExecutiveList([
            ...data.map(({ nombres, apellidos, rut, subsidiary }) => ({
              rut,
              nombre: `${nombres} ${apellidos}`,
              subsidiary,
            })),
          ]);
        } else {
          throw Error('no hay data');
        }
      },
      err => {
        console.error(err);
      },
    );
  }, []);

  return { executiveList, activeExecutive, setActiveExecutive };
};

const ExecutiveList = () => {
  const {
    state: { executiveRut },
  } = useContext(EnterpriseContext);
  const { executiveList } = useExecutiveList(executiveRut);
  return (
    <div className="ExecutiveList">
      <Typography variant="subtitle1">SELECCION DE EJECUTIVO</Typography>
      {executiveList.map(executive => (
        <ExecutiveListItem {...executive} key={executive.rut} />
      ))}
    </div>
  );
};

export default ExecutiveList;
