import React, { useState, useEffect } from 'react';
import {
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import Button from '../ButtonTypes';
import RolesDialog from './RolesDialog';
import { updateUserRoles, updateUserProfiles } from '../../services/projectsService';
import { transformBodyForUpdateUserProfiles } from '../../utils/permissions';

const nameMap = {
  'En Escritura': 'Escritura',
  'En Promesa': 'Promesa',
  'En Entrega': 'Entrega',
  'En Postventa': 'Postventa',
};

const ProfilePermissionsMatrix = ({
  executive,
  profiles,
  allRoles,
  executivePermissions,
  subsidiaries,
  loadPermissions,
  userProfiles,
}) => {
  const [execPermissions, setExecPermissions] = useState({});
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [changedPermissions, setChangedPermissions] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentProfile, setCurrentProfile] = useState('');
  const [currentFilial, setCurrentFilial] = useState('');
  const handleOpenModal = (perfil, filial) => {
    setCurrentProfile(perfil);
    setCurrentFilial(filial);
    setIsModalOpen(true);
  };

  const handleSaveModal = data => {
    updateUserRoles(
      data,
      () => {
        loadPermissions();
      },
      error => {
        console.error(error);
      },
    );
    setIsModalOpen(false);
    setShowSaveButton(false);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const strippedProfilesObject = profiles.map(profile => ({
    ...profile,
    nombrePerfil: profile.nombrePerfil.trim(),
  }));

  useEffect(() => {
    const initialPermissions = {};
    profiles.forEach(profile => {
      subsidiaries.forEach(filial => {
        const key = `${profile.idPerfil}_${filial}`;
        const isSelected = userProfiles.some(
          userProfile =>
            userProfile.filiales.split(',').includes(filial) && userProfile.idPerfil === profile.idPerfil
        );
        initialPermissions[key] = isSelected;
      });
    });
    setExecPermissions(initialPermissions);
    const truePermissions = Object.entries(initialPermissions).reduce((acc, [key, value]) => {
      if (value) acc[key] = value;
      return acc;
    }, {});
    setChangedPermissions(truePermissions);
  }, [userProfiles]);

  const handleSave = () => {
    const updateBody = transformBodyForUpdateUserProfiles(changedPermissions, executive);
    updateUserProfiles(
      updateBody,
      () => {
        console.log('success!');
      },
      error => {
        console.error(error);
      },
    );
    setShowSaveButton(false);
  };

  const handlePermissionChange = (perfilId, filial, isChecked) => {
    const key = `${perfilId}_${filial}`;
    setExecPermissions(prevPermissions => {
      const newPermissions = { ...prevPermissions, [key]: isChecked };
      setChangedPermissions(prev => {
        if (isChecked) {
          return { ...prev, [key]: isChecked };
        }
        const { [key]: omit, ...rest } = prev;
        return rest;
      });
      return newPermissions;
    });
    setShowSaveButton(true);
  };

  const getProfilePermissions = (theseSubsidiaries, profile, filial) => {
    const modifiedProfile = profile === 'Intranet' ? profile : `En ${profile}`;
    const subsidiary = theseSubsidiaries.find(sub => sub.name === filial);
    const incumbent = subsidiary?.incumbents.find(inc => inc.type === modifiedProfile);
    return incumbent?.roles.map(role => role.name) || [];
  };

  const renderCellContent = (perfil, filial) => {
    const key = `${perfil.idPerfil}_${filial}`;
      return (
        <input
          type="checkbox"
          checked={execPermissions[key]}
          onChange={e => handlePermissionChange(perfil.idPerfil, filial, e.target.checked)}
        />
      );
  };

  const RenderPermissionRow = ({ profile, subsidiaries, renderCellContent }) => (
    <>
      <TableRow key={profile.idPerfil}>
        <TableCell>{profile.nombrePerfil}</TableCell>
        {subsidiaries.map(filial => (
          <TableCell key={filial}>{renderCellContent(profile, filial)}</TableCell>
        ))}
      </TableRow>
    </>
  );

  const PermissionTable = ({ subsidiaries, strippedProfilesObject, renderCellContent }) => (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <strong>PERFIL</strong>
            </TableCell>
            {subsidiaries.map(filial => (
              <TableCell key={filial}>
                <strong>{filial.toUpperCase()}</strong>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {strippedProfilesObject.map(perfil => (
            <RenderPermissionRow
              profile={perfil}
              subsidiaries={subsidiaries}
              renderCellContent={renderCellContent}
            />
          ))}
        </TableBody>
      </Table>
    </>
  );
  return (
    <div>
        <h3>Asignación de perfiles</h3>
      <PermissionTable
        subsidiaries={subsidiaries}
        strippedProfilesObject={strippedProfilesObject}
        renderCellContent={renderCellContent}
      />
      {showSaveButton && (
        <div>
          <Button style={{ 'marginTop': '2rem' }} onClick={handleSave}>
            Guardar cambios
          </Button>
        </div>
      )}
      <RolesDialog
        executive={executive}
        subsidiary={currentFilial}
        profile={currentProfile}
        onPermissionsUpdate={loadPermissions}
        executivePermissions={getProfilePermissions(
          executivePermissions.subsidiaries,
          currentProfile.nombrePerfil,
          currentFilial,
        )}
        allPermissions={allRoles}
        open={isModalOpen}
        handleCancel={handleCloseModal}
        handleSave={handleSaveModal}
      />
    </div>
  );
};

export default ProfilePermissionsMatrix;
