import { USER_LOGIN, USER_LOGOUT, TOOGLE_STATE_LOAD } from '../actions/actionTypes';

export const inicialState = {
  token: sessionStorage.getItem('token'),
  rut: sessionStorage.getItem('rut') || '',
  fullName: sessionStorage.getItem('fullName') || '',
  username: sessionStorage.getItem('username') || '',
  idAdditional: sessionStorage.getItem('idAdditional') || '',
  subsidiary: sessionStorage.getItem('subsidiary') || '',
  isOwner: sessionStorage.getItem('isOwner') || '',
  isAdditional: sessionStorage.getItem('isAdditional') || '',
  isAdmin: sessionStorage.getItem('isAdmin') || '',
  email: '',
  permissions: JSON.parse(sessionStorage.getItem('permissions')) || [],
  rehydrate: false,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case USER_LOGIN:
      return {
        ...state,
        token: action.token,
        username: action.username,
        idAdditional: action.idAdditional,
        fullName: action.fullName,
        rut: action.rut,
        subsidiary: action.subsidiary,
        email: action.mail,
        isOwner: action.isOwner,
        isAdditional: action.isAdditional,
        isAdmin: action.isAdmin,
        permissions: action.permissions,
      };
    case USER_LOGOUT:
      if (action.callback) action.callback();
      return {
        ...state,
        token: undefined,
        username: undefined,
        idAdditional: undefined,
        fullName: undefined,
        rut: undefined,
        subsidiary: undefined,
        isOwner: undefined,
        isAdditional: undefined,
        isAdmin: undefined,
        permissions: [],
      };
    case TOOGLE_STATE_LOAD:
      return {
        ...state,
        rehydrate: !state.rehydrate,
      };
    default:
      return {
        ...state,
      };
  }
};
