const transformBodyForUpdateUserProfiles = (changedPermissions, executive) => {
  const profileList = [];
  const filialList = [];

  Object.keys(changedPermissions).forEach(key => {
    const [perfilId, filial] = key.split('_');
    profileList.push(perfilId);
    filialList.push(filial);
  });

  const dataToSend = {
    usuario: executive.rut,
    listaperfiles: profileList.join(','),
    listafiliales: filialList.join(','),
  };
  return dataToSend;
};

export { transformBodyForUpdateUserProfiles };
