/* eslint-disable no-confusing-arrow */
import React, { memo } from 'react';
import PropTypes from 'prop-types';

// Components
import ProjectButton from '../ProjectButton';
import ClientStatus from '../ClientStatus';
import { getUniquePropertyStates } from '../../customHooks/usePermissions';

import './ProjectTableItem.scss';

const ProjectTableItem = ({
  progress,
  onClick,
  id,
  onClientStatusChange,
  mobile = false,
  email = '',
  ...rest
}) => {
  return !mobile ? (
    <tr className="ProjectTableItem">
      {Object.keys(rest).map(key =>
        !rest[key].circle ? (
          <td className="ProjectTableItem__column" key={rest[key].value}>
            {key === 'estado_sistema' ? (
              <ClientStatus
                clientStatus={rest[key].value}
                clientId={id}
                onClientStatusChange={onClientStatusChange}
                email={email}
                cliente={rest.fullName.value}
              />
            ) : (
              rest[key].value
            )}
          </td>
        ) : (
          <td className="ProjectTableItem__column__center" key={rest[key].value}>
            <span className="ProjectTableItem__column__circle">{rest[key].value}</span>
          </td>
        ),
      )}
      <td className="ProjectTableItem__column">
        <ProjectButton
          className="ProjectTableItem__progress__button"
          subsidiary="empresas"
          onClick={() => onClick(id)}
        />
      </td>
    </tr>
  ) : (
    <div className="ProjectTableItem__responsive">
      {
        <div className="ProjectTableItem__responsive__container">
          <div className="ProjectTableItem__responsive__itemContainer">
            <div className="ProjectTableItem__responsive__item">
              <span className="ProjectTableItem__responsive__item__label">
                {rest[Object.keys(rest)[0]].label}
              </span>
              <span className="ProjectTableItem__responsive__item__value">
                {rest[Object.keys(rest)[0]].value}
              </span>
            </div>
            <div className="ProjectTableItem__responsive__item">
              <ProjectButton
                className="ProjectTableItem__responsive__item__button"
                subsidiary="empresas"
                onClick={() => onClick(id)}
              />
            </div>
          </div>
          <div className="ProjectTableItem__responsive__itemContainer">
            <div className="ProjectTableItem__responsive__item ProjectTableItem__responsive__item--fullSize">
              <span className="ProjectTableItem__responsive__item__label">
                {rest[Object.keys(rest)[1]].label}
              </span>
              <span className="ProjectTableItem__responsive__item__value">
                {rest[Object.keys(rest)[1]].value}
              </span>
            </div>
          </div>
          <div className="ProjectTableItem__responsive__itemContainer">
            <div className="ProjectTableItem__responsive__item ProjectTableItem__responsive__item--fullSize">
              <span className="ProjectTableItem__responsive__item__label">
                {rest[Object.keys(rest)[2]].label}
              </span>
              <span className="ProjectTableItem__responsive__item__value">
                {rest[Object.keys(rest)[2]].value}
              </span>
            </div>
          </div>
          {rest[Object.keys(rest)[3]] &&
            rest[Object.keys(rest)[3]].value &&
            rest[Object.keys(rest)[3]].label && (
              <div className="ProjectTableItem__responsive__itemContainer">
                <div className="ProjectTableItem__responsive__item ProjectTableItem__responsive__item--fullSize">
                  <span className="ProjectTableItem__responsive__item__label">
                    {rest[Object.keys(rest)[3]].label}
                  </span>
                  <span className="ProjectTableItem__responsive__item__value">
                    {rest[Object.keys(rest)[3]].value}
                  </span>
                </div>
              </div>
            )}
        </div>
      }
    </div>
  );
};

ProjectTableItem.propTypes = {
  progress: PropTypes.object,
  id: PropTypes.string,
  eMailParticular: PropTypes.string,
  estado_sistema: PropTypes.object,
  rest: PropTypes.object,
  onClick: PropTypes.func,
  onClientStatusChange: PropTypes.func,
  itemWith: PropTypes.string,
  mobile: PropTypes.bool,
};

export default memo(ProjectTableItem);
