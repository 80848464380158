import React, { useState, useEffect } from 'react';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import IconButton from '@material-ui/core/IconButton';
import Button from '../ButtonTypes';
import { updateProfileRoles } from '../../services/projectsService';
import './RolesCard.scss';

const RolesCard = ({ id, name, selectedProfileRoles, setSelectedProfileRoles, isExpanded, allPermissions, handleExpand }) => {
  const [modifiedRoles, setModifiedRoles] = useState({});
  const [showSave, setShowSave] = useState(false);
  const [isExpandedLocal, setIsExpandedLocal] = useState(isExpanded);

  useEffect(() => {
    setIsExpandedLocal(isExpanded);
  }, [isExpanded]);

  const isPermissionChecked = permission => {
    // If the permission exists in modifiedRoles, its checked state is the opposite of its initial state.
    // If it's not in modifiedRoles, its checked state is determined by whether it's included in selectedProfileRoles.
    if (modifiedRoles.hasOwnProperty(permission.idRol)) {
      return !selectedProfileRoles.some(p => p.idRol === permission.idRol);
    }
    return selectedProfileRoles.some(p => p.idRol === permission.idRol);
  };


  const toggleExpansion = () => {
    setIsExpandedLocal(!isExpandedLocal);
    if (!isExpandedLocal) {
      handleExpand();
    }
  };

  const handleCheckboxChange = role => {
    setModifiedRoles(prev => {
      const newModifiedRoles = { ...prev };
      if (prev[role.idRol]) {
        // If the role is already in modifiedRoles, remove it (toggle off)
        delete newModifiedRoles[role.idRol];
      } else {
        // Otherwise, add it (toggle on)
        newModifiedRoles[role.idRol] = true; // Just need to mark it as modified, no need to store the whole role object
      }
      return newModifiedRoles;
    });
    setShowSave(true);
  };

  const handleSave = () => {
    // Start with a copy of the original selectedProfileRoles
    let updatedRoles = [...selectedProfileRoles];
    // Iterate over modifiedRoles to determine additions or removals
    Object.keys(modifiedRoles).forEach(idRol => {
      const numericIdRol = Number(idRol);
      const roleIndex = updatedRoles.findIndex(role => role.idRol === numericIdRol);
  
      if (roleIndex > -1) {
        updatedRoles.splice(roleIndex, 1);
      } else {
        const roleToAdd = allPermissions.find(role => role.idRol === numericIdRol);
        if (roleToAdd) updatedRoles.push(roleToAdd);
      }
    });
    const saveObject = {
      profileId: id,
      roles: updatedRoles,
    };
    updateProfileRoles(
      saveObject,
      response => {
        setSelectedProfileRoles(updatedRoles);
        setModifiedRoles({});
        setShowSave(false);
      },
      error => {
        console.error(error);
      },
    );
  };

  const handleCancel = () => {
    setModifiedRoles({});
    setShowSave(false);
  };
  return (
    <div className="RolesCardItem">
      <div className="RolesCardItem__header">
        <h2 className="RolesCardItem__title">{name}</h2>
        <IconButton onClick={toggleExpansion} className="RolesCardItem__expandButton">
          {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </div>
      {isExpanded && (
        <div className="RolesCardItem__content">
          {allPermissions.map(thisPermission => (
            <FormControlLabel
              key={thisPermission.idRol}
              control={
                <Checkbox
                  checked={isPermissionChecked(thisPermission)}
                  onChange={() => handleCheckboxChange(thisPermission)}
                  color="primary"
                />
              }
              label={thisPermission.nombreRol}
            />
          ))}
        </div>
      )}
      {showSave && (
        <div className="RolesCardItem__footer">
          <Button onClick={handleSave}>Guardar cambios</Button>
          <Button onClick={handleCancel} color="secondary">
            Cancelar
          </Button>
        </div>
      )}
    </div>
  );
};

export default RolesCard;
