import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import DeleteButton from '../DeleteButton';
import FileIcon from '../Icons/FileIcon';
import Button from '../ButtonTypes';

import './MultipleUploadItem.scss';

const MultipleUploadItem = ({ name, to, onDelete, onClick, id, canDelete }) => (
  <li className="MultipleUploadItem">
    <FileIcon className="MultipleUploadItem__icon" />
    {onClick ? (
      <Button variant="text" onClick={onClick}>
        <span className="MultipleUploadItem__name">{name}</span>
        {' - '} {to && to.toLowerCase()}
      </Button>
    ) : (
      <Fragment>
        <span className="MultipleUploadItem__name">{name}</span> {' - '} {to && to.toLowerCase()}
      </Fragment>
    )}
    {onDelete && canDelete && (
      <DeleteButton style={{ display: 'flex', marginLeft: 'auto' }} onClick={() => onDelete(id)} />
    )}
  </li>
);

MultipleUploadItem.propTypes = {
  name: PropTypes.string,
  to: PropTypes.string,
  onDelete: PropTypes.func,
  onClick: PropTypes.func,
  id: PropTypes.string,
};

export default MultipleUploadItem;
