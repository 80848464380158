import React, { memo, useContext, useState } from 'react';
import PropTypes from 'prop-types';

import { Switch } from 'react-router-dom';

// Components
import Grid from '@material-ui/core/Grid';
import ProtectedRoute from '../../components/ProtectedRoute';
import Menu from '../../components/Menu';
import Navbar from '../../components/Navbar';
import EnterpriseHome from '../../screens/EnterpriseHome';
import EnterpriseMessages from '../../screens/EnterpriseMessages';
import EnterpriseExecutiveList from '../../screens/EnterpriseExecutiveList';
import EnterpriseExecutiveDetail from '../../screens/EnterpriseExecutiveDetail';
import EnterpriseClientList from '../../screens/ClientList';
import SubsidiaryScreenList from '../../screens/SubsidiaryScreenList';
import RolesScreen from '../../screens/RolesScreen';
import DocumentsClassification from '../../screens/DocumentsClassification';

import ProjectDetail from '../../screens/EnterpriseHome/ProjectDetail';
import ClientDetail from '../../screens/ClientDetail';

// Provider
import { EnterpriseProvider } from '../../providers/EnterpriseProvider';
import { GlobalContext } from '../../providers/GlobalStateProvider';
import { useNotificationList } from '../../customHooks/fetchData';

import EnterpriseLogo from '../../assets/logo-socovesa-white.svg';

import './EnterpriseLayout.scss';
import ClientAdditional from '../../screens/ClientAdditional/ClientAdditional';

const EnterpriseLayout = ({
  match: {
    path,
    url,
    params: { subsidiary },
  },
  history,
}) => {
  //const {state: { subsidiary }} = useContext(GlobalContext);
  const logOutCallback = () => {
    history.push(`/${subsidiary}`);
  };
  const checkMessages = () => {
    history.push(`/${subsidiary}/Home/messages`);
  };
  const { logOut, state: { username = '' } = {} } = useContext(GlobalContext);
  const [readMessages, setReadMessages] = useState(false);
  const { notificationList } = useNotificationList(username, readMessages);

  const onReadMessages = () => {
    setReadMessages(!readMessages)
  }
  return (
    <Grid container className={`EnterpriseLayout EnterpriseLayout--${subsidiary}`}>
      <Grid item sm={3} className="EnterpriseLayout__sidebar">
        <div className="EnterpriseLayout__sidebar__container">
          <img
            alt="logo"
            className="EnterpriseLayout__sidebar__container__logo"
            src={EnterpriseLogo}
          />
          <Menu className="EnterpriseLayout__sidebar__container__Menu" subsidiary={subsidiary} />
        </div>
      </Grid>
      <Grid item sm={9} xs={12} className="EnterpriseLayout__body">
        <Navbar
          subsidiary={subsidiary}
          messageCallback={checkMessages}
          logOutCallback={() => logOut(logOutCallback)}
          notificationList={notificationList}
          urlBase={url}
          isExecutive
        />
        <EnterpriseProvider>
          <Switch>
            <ProtectedRoute
              path={`${path}/subsidiaries`}
              exact
              render={props => <SubsidiaryScreenList {...props} />}
            />
            <ProtectedRoute
              path={`${path}/profiles`}
              exact
              render={props => <RolesScreen {...props} />}
            />
            <ProtectedRoute
              path={`${path}/documents`}
              exact
              render={props => <DocumentsClassification {...props} />}
            />
            <ProtectedRoute
              path={`${path}/`}
              exact
              render={props => <EnterpriseHome {...props} notificationList={notificationList} />}
            />
            <ProtectedRoute path={`${path}/messages`} exact render={props => <EnterpriseMessages {...props} onReadMessages={onReadMessages}/>} />
            <ProtectedRoute path={`${path}/clients`} exact component={EnterpriseClientList} />
            <ProtectedRoute path={`${path}/clients/:idClient`} exact render={props => <ClientDetail {...props} onReadMessages={onReadMessages}/>} />
            <ProtectedRoute path={`${path}/clients/:idClient/additional`} exact render={props => <ClientAdditional {...props}/>} />
            <ProtectedRoute
              path={`${path}/executives/list/:updated?`}
              exact
              component={EnterpriseExecutiveList}
            />
            <ProtectedRoute
              path={`${path}/executives/detail/:idExecutive`}
              exact
              component={EnterpriseExecutiveDetail}
            />
            <ProtectedRoute path={`${path}/:idProject`} exact component={ProjectDetail} />
            <ProtectedRoute path={`${path}/:idProject/:idClient/additional`} render={props => <ClientAdditional {...props}/>} />
            <ProtectedRoute path={`${path}/:idProject/:idClient`} render={props => <ClientDetail {...props} onReadMessages={onReadMessages}/>} />
          </Switch>
        </EnterpriseProvider>
      </Grid>
    </Grid>
  );
};

EnterpriseLayout.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
    params: PropTypes.shape({
      subsidiary: PropTypes.string,
    }),
  }),
  history: PropTypes.object,
};

export default memo(EnterpriseLayout);
