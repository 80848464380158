// authtentication
export const loginEndpoint = `${process.env.REACT_APP_SERVER_URL}/cliente/login`;
export const executiveLoginEndpoint = `${process.env.REACT_APP_SERVER_URL}/ejecutivo/login`;
export const recoverPassEndpoint = `${process.env.REACT_APP_SERVER_URL}/auth/recuperar`;
export const restablecerPassEndpoint = `${process.env.REACT_APP_SERVER_URL}/auth/restablecer`;

// projects
export const projectsByClient = `${process.env.REACT_APP_SERVER_URL}/proyectos/cliente`;
export const propertiesByProject = `${process.env.REACT_APP_SERVER_URL}/propiedades/proyecto/cliente`;
export const milestonesByProject = `${process.env.REACT_APP_SERVER_URL}/propiedades/hitos`;
export const documentsByProject = `${process.env.REACT_APP_SERVER_URL}/documentos/listarDocs`;
export const clientsByProject = `${process.env.REACT_APP_SERVER_URL}/clientes/proyecto`;
export const projectById = `${process.env.REACT_APP_SERVER_URL}/proyecto`;
export const notificacion = `${process.env.REACT_APP_SERVER_URL}/notificacion`;
export const statusByBusinessId = `${process.env.REACT_APP_SERVER_URL}/cliente/estadoNegocio`;
export const UpdateStatusByBusinessId = `${process.env.REACT_APP_SERVER_URL}/cliente/actualizarEstadoNegocio`;

// messages
export const messagesByProject = `${process.env.REACT_APP_SERVER_URL}/mensajes`;
export const answerMessage = `${process.env.REACT_APP_SERVER_URL}/mensaje/responder`;
export const sendMessage = `${process.env.REACT_APP_SERVER_URL}/mensaje`;
export const markAsRead = `${process.env.REACT_APP_SERVER_URL}/mensaje/marcarLeido`;
export const filterMessagesByText = `${process.env.REACT_APP_SERVER_URL}/mensajes/buscar`;
export const massiveMessage = `${process.env.REACT_APP_SERVER_URL}/mensajeMasivo`;

// enterprise
export const projectsByExecutive = `${process.env.REACT_APP_SERVER_URL}/proyectos/ejecutivo`;

// executive
export const getExecutiveList = `${process.env.REACT_APP_SERVER_URL}/ejecutivosSocovesa`;

// calendar
export const getCalendars = `${process.env.REACT_APP_SERVER_URL}/calendario/listar`;

export const getExecutiveDetail = `${process.env.REACT_APP_SERVER_URL}/ejecutivo`;

// clients
export const postNote = `${process.env.REACT_APP_SERVER_URL}/nota`;
export const editNote = `${process.env.REACT_APP_SERVER_URL}/nota/`;
export const deletetNote = `${process.env.REACT_APP_SERVER_URL}/nota/`;
export const listClientNotes = `${process.env.REACT_APP_SERVER_URL}/notas`;
export const getClients = `${process.env.REACT_APP_SERVER_URL}/clientes/ejecutivo`;
export const activateClient = `${process.env.REACT_APP_SERVER_URL}/cliente/activar`;
export const reEnviarCorreoActivacion = `${process.env.REACT_APP_SERVER_URL}/cliente/bienvenida`;
export const clientByRut = `${process.env.REACT_APP_SERVER_URL}/cliente`;
export const getExecutiveByClient = `${process.env.REACT_APP_SERVER_URL}/cliente/ejecutivo`;

// notificaciones
export const listNotifications = `${process.env.REACT_APP_SERVER_URL}/notificacion`;
export const markAsView = `${process.env.REACT_APP_SERVER_URL}/notificacion/vista`;

// files
export const uploadFileMassive = `${process.env.REACT_APP_SERVER_URL}/documentos/uploadFileMasivo`;
export const deleteFileMassive = `${process.env.REACT_APP_SERVER_URL}/documentos/eliminarDocMasivo/`;
export const filesByProperty = `${process.env.REACT_APP_SERVER_URL}/documentos/listarDocs`;
export const uploadFile = `${process.env.REACT_APP_SERVER_URL}/documentos/uploadFile`;
export const deleteFile = `${process.env.REACT_APP_SERVER_URL}/documentos/eliminarDoc`;
export const activeClientsByProject = `${process.env.REACT_APP_SERVER_URL}/documentos/clientes/propiedad`;
export const listMassiveDocs = `${process.env.REACT_APP_SERVER_URL}/documentos/listarDocsMasivos`;
export const downloadFile = `${process.env.REACT_APP_SERVER_URL}/documentos/downloadFile`;
export const uploadFileMessages = `${process.env.REACT_APP_SERVER_URL}/documentos/uploadFileMessage`;
export const listarImages = `${process.env.REACT_APP_SERVER_URL}/documentos/listarImages`;
export const listExistingDocs = `${process.env.REACT_APP_SERVER_URL}/documentos/getExistingDocs`;
export const updateDocClassification = `${process.env.REACT_APP_SERVER_URL}/documentos/updateDocClassification`;
export const listDocAgrupations = `${process.env.REACT_APP_SERVER_URL}/documentos/getDocAgrupations`;

// Mileston Calendar
export const createNewMilestonDate = `${process.env.REACT_APP_SERVER_URL}/calendario/agregar`;
export const listMilestonDate = `${process.env.REACT_APP_SERVER_URL}/calendario/listar`;
export const editMilestonDate = `${process.env.REACT_APP_SERVER_URL}/calendario/editar`;
export const deleteMilestonDate = `${process.env.REACT_APP_SERVER_URL}/calendario/eliminar`;

// projectImages
export const uploadProjectImage = `${process.env.REACT_APP_SERVER_URL}/images/uploadProyectImage`;
export const listProjectImage = `${process.env.REACT_APP_SERVER_URL}/images/listProyectsImages`;
export const deleteProjectImage = `${process.env.REACT_APP_SERVER_URL}/images/deleteImage`;

// Avatar Images
export const getAvatarImage = `${process.env.REACT_APP_SERVER_URL}/images/getAvatarImage`;
export const setAvatarImage = `${process.env.REACT_APP_SERVER_URL}/images/setAvatarImage`;

// documentos agrupados
export const projectDocumentsList = `${process.env.REACT_APP_SERVER_URL}/documentos/listProjectDocuments`;
export const clientDocumentsList = `${process.env.REACT_APP_SERVER_URL}/documentos/listClientDocuments`;
export const UploadFileProject = `${process.env.REACT_APP_SERVER_URL}/documentos/uploadFileProject`;
export const UploadFileClient = `${process.env.REACT_APP_SERVER_URL}/documentos/uploadFileClient`;
export const deleteFileProject = `${process.env.REACT_APP_SERVER_URL}/documentos/deleteProjectDocument`;

// PostVenta
export const PropiedadesporClientePV = `${process.env.REACT_APP_SERVER_URL}/PropiedadesporClientePV`;
export const PropiedadesporClientePVAdditional = `${process.env.REACT_APP_SERVER_URL}/PropiedadesporAdicional`;
export const PropiedadesporAdministradorPV = `${process.env.REACT_APP_SERVER_URL}/PropiedadesporAdministradorPV`;
export const SolicitudesporUnidadPV = `${process.env.REACT_APP_SERVER_URL}/SolicitudesporUnidadPV`;
export const RequerimientosPorSolicitud = `${process.env.REACT_APP_SERVER_URL}/RequerimientosPorSolicitudPV`;
export const DocumentosPorSolicitud = `${process.env.REACT_APP_SERVER_URL}/DocumentosSolicitudPV`;
export const RecintosPorUnidad = `${process.env.REACT_APP_SERVER_URL}/RecintosporUnidad`;
export const ElementosPorRecinto = `${process.env.REACT_APP_SERVER_URL}/ElementosporRecinto`;
export const ComponentesPorElemento = `${process.env.REACT_APP_SERVER_URL}/ComponentesporElemento`;
export const FallasPorComponente = `${process.env.REACT_APP_SERVER_URL}/FallasporComponente`;
export const AgregarSolicitud = `${process.env.REACT_APP_SERVER_URL}/AgregarSolicitud`;
export const AgregarRequerimiento = `${process.env.REACT_APP_SERVER_URL}/AgregarRequerimiento`;
export const ActualizarRequerimiento = `${process.env.REACT_APP_SERVER_URL}/ModificarRequerimiento`;
export const getImagenesPorRequerimiento = `${process.env.REACT_APP_SERVER_URL}/GetImagenesRequerimiento`;
export const AgregarImagenRequerimiento = `${process.env.REACT_APP_SERVER_URL}/AgregarImagenRequerimiento`;
export const ModificarImagenRequerimiento = `${process.env.REACT_APP_SERVER_URL}/ModificarImagenRequerimiento`;
export const ConfirmarSolicitud = `${process.env.REACT_APP_SERVER_URL}/ConfirmarSolicitud`;
export const NotificarRequerimiento = `${process.env.REACT_APP_SERVER_URL}/NotificarRequerimiento`;
export const ActasbyProducto = `${process.env.REACT_APP_SERVER_URL}/ListaActaxUnidad`;
export const ImagenActa = `${process.env.REACT_APP_SERVER_URL}/ImagenActa`;

// PostVenta - Adicionales
export const CrearAdicional = `${process.env.REACT_APP_SERVER_URL}/Adicional/CrearAdicional`;
export const ModificarAdicional = `${process.env.REACT_APP_SERVER_URL}/Adicional/ModificarAdicional`;
export const AdicionalesPorRut = `${process.env.REACT_APP_SERVER_URL}/Adicional/GetAdicional`;
export const RelacionesPorRut = `${process.env.REACT_APP_SERVER_URL}/Adicional/GetRelaciones`;

// Proyectos - PermisosFront
export const MenuDisponible = `${process.env.REACT_APP_SERVER_URL}/MenuDisponible`;
export const ListarMenusDisponibles = `${process.env.REACT_APP_SERVER_URL}/ListarMenusDisponibles`;
export const ModificarMenusDisponibles = `${process.env.REACT_APP_SERVER_URL}/ModificarMenuDisponible`;

export const rolesListEndpoint = `${process.env.REACT_APP_SERVER_URL}/listRoles`;
export const profileListEndpoint = `${process.env.REACT_APP_SERVER_URL}/listProfiles`;

export const userSubsidiaryProfileRoleListEndpoint = `${process.env.REACT_APP_SERVER_URL}/listUserSubsidiaryProfiles`;
export const userProfiles = `${process.env.REACT_APP_SERVER_URL}/listUserProfiles`;
export const modifyProfileRoles = `${process.env.REACT_APP_SERVER_URL}/updateProfile`;
export const modifyUserRoles = `${process.env.REACT_APP_SERVER_URL}/updateUserProfile`;
export const modifyUserProfileAssignment = `${process.env.REACT_APP_SERVER_URL}/updateUserProfileAssignment`;

export const unitTypesByProject = `${process.env.REACT_APP_SERVER_URL}/projectUnitTypes`;
export const clientsByUnitType = `${process.env.REACT_APP_SERVER_URL}/clientsByUnitType`;
