import React, { useState, useEffect } from 'react';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import IconButton from '@material-ui/core/IconButton';
import Button from '../ButtonTypes';
import { updateDocumentClassification } from '../../services/projectsService';
import './DocClassificationCard.scss'; // Importamos el archivo de estilos

const DocClassificationCard = ({ 
    id, 
    name, 
    selectedDocTypeOptions, 
    isExpanded, 
    allOptions,
    classificationsNames,
    consultaAgrupacionDocumentos }) => {
	const [modifiedOptions, setModifiedOptions] = useState({});
	const [showSave, setShowSave] = useState(false);
	const [isExpandedLocal, setIsExpandedLocal] = useState(isExpanded);
	
	useEffect(() => {
    setIsExpandedLocal(isExpanded);
  }, [isExpanded]);

  const groupedOptions = Object.entries(allOptions).reduce((groups, [key, value]) => {
    const classificationName = classificationsNames[key];
    const groupName = consultaAgrupacionDocumentos.find(
      doc => doc.nombreAgrupacion === key
    )?.nombreClasificacion;
  
    if (!groups[groupName]) {
      groups[groupName] = [];
    }
    groups[groupName].push({ key, label: classificationName });
    return groups;
  }, {});

	const isOptionChecked = option => {
    if (modifiedOptions.hasOwnProperty(option)) {
      return !selectedDocTypeOptions.some(p => p.clasificacion === option);
    }
    return selectedDocTypeOptions.some(p => p.clasificacion === option);
  };

	const toggleExpansion = () => {
    setIsExpandedLocal(!isExpandedLocal);
  };

	const handleCheckboxChange = option => {
    setModifiedOptions(prev => {
      const newModifiedOptions = { ...prev };
      if (prev[option]) {
        delete newModifiedOptions[option];
      } else {
        newModifiedOptions[option] = true;
      }
      return newModifiedOptions;
    });
    setShowSave(true);
  };

	const handleSave = () => {
    let updatedOptions = { ...selectedDocTypeOptions.reduce((acc, option) => {
      acc[option.clasificacion] = 1;
      return acc;
    }, {}) };

    Object.keys(modifiedOptions).forEach(optionKey => {
      if (updatedOptions[optionKey]) {
        delete updatedOptions[optionKey];
      } else {
        updatedOptions[optionKey] = 1;
      }
    });

    const body = {
      id,
      tipodocumento: name,
      ...Object.keys(allOptions).reduce((acc, key) => {
        acc[key] = updatedOptions[key] ? 1 : 0;
        return acc;
      }, {})
    };

    updateDocumentClassification(
      body,
      response => {
        setShowSave(false);
        window.location.reload();
      },
      error => {
        console.error('Error al actualizar:', error);
      }
    );
  };

	const handleCancel = () => {
    setModifiedOptions({});
    setShowSave(false);
  };

	return (
    <div className="doc-classification-card">
      <div className="header">
        <h2 className="title">{id} - {name}</h2>
        <IconButton onClick={toggleExpansion} className="expand-button">
          {isExpandedLocal ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </div>
      {isExpandedLocal && (
        <div className="content">
          {Object.entries(groupedOptions).map(([groupName, options]) => (
            <div className="group" key={groupName}>
              <h3 className="group-title">{groupName}</h3>
              <div className="options">
                {options.map(({ key, label }) => (
                  <FormControlLabel
                    key={key}
                    control={
                      <Checkbox
                        checked={ isOptionChecked(key) }
                        onChange={() => handleCheckboxChange(key)}
                        color="primary"
                      />
                    }
                    label={label}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
      {showSave && (
        <div className="footer">
          <Button onClick={handleSave}>Guardar cambios</Button>
          <Button onClick={handleCancel} color="secondary">
            Cancelar
          </Button>
        </div>
      )}
    </div>
  );

};

export default DocClassificationCard;