import React, { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import Button from '../../components/ButtonTypes';
import { listAllProfiles, listAllRoles, listRolesByProfile } from '../../services/projectsService';

// Component
import RolesCard from '../../components/RolesCard';
import './RolesScreen.scss';

const RolesScreen = () => {
  const [profiles, setProfiles] = useState([]);
  const [allRoles, setAllRoles] = useState([]);
  const [selectedProfileRoles, setSelectedProfileRoles] = useState([]);
  const [selectedProfileId, setSelectedProfileId] = useState(null);

  // Fetch Profiles and Roles on component mount
  useEffect(() => {
    listAllProfiles(response => setProfiles(response.data.data), error => console.error(error));
    listAllRoles(response => setAllRoles(response.data.data), error => console.error(error));
  }, []);

  // Fetch roles for a specific profile when it's clicked
  const handleProfileClick = profileId => {
    if (selectedProfileId === profileId) {
      setSelectedProfileId(null);
      setSelectedProfileRoles([]);
    } else {
      listRolesByProfile(
        profileId,
        response => {
          setSelectedProfileId(profileId);
          setSelectedProfileRoles(response.data.data);
        },
        error => console.error(error),
      );
    }
  };
  return (
    <div className="RolesScreenList">
      <Typography variant="h2" className="RolesScreenList__title">
        PERFILES DE INTRANET
      </Typography>
      <div>
        <Button disabled> Nuevo Perfil </Button>
      </div>
      {profiles.map(profile => (
        <RolesCard
          key={profile.idPerfil}
          id={profile.idPerfil}
          name={profile.nombrePerfil.trim()}
          selectedProfileRoles={selectedProfileId === profile.idPerfil ? selectedProfileRoles : []}
          setSelectedProfileRoles={setSelectedProfileRoles}
          isExpanded={selectedProfileId === profile.idPerfil}
          allPermissions={allRoles}
          handleExpand={() => handleProfileClick(profile.idPerfil)}
        />
      ))}
    </div>
  );
};
export default RolesScreen;
